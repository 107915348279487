<template>
  <div
    style="background: #f2f2f2"
    class="min-h-screen flex items-center"
    v-if="localStorageParse"
  >
    <div
      class="
        w-full
        lg:w-3/5
        xl:w-2/5
        px-5
        lg:px-0
        py-5
        lg:py-3
        mx-auto
        flex flex-col
        gap-y-4
      "
    >
      <template v-if="!isDone">
        <template v-if="!deleteBackground">
          <nav>
            <a
              class="flex items-center gap-x-1 text-black font-EffraR text-lg"
              href="#back"
              @click="
                () => {
                  this.$router.go(-1);
                }
              "
              ><svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="#111"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M11.25 13.5L6.75 9l4.5-4.5"
                />
              </svg>

              {{ $t("patient.back") }}
            </a>
          </nav>

          <div class="bg-white w-full shadow rounded-cu p-5">
            <appointment-info />
            <button
              class="
                mt-5
                border-0
                bg-transparent
                font-EffraM
                text-lg text-rosDok-ultra
                cursor-pointer
                uppercase
              "
              @click="
                () => {
                  goToDoctorProfile(JSON.parse(localStorageParse).doctor);
                }
              "
            >
              {{ $t("patient.change_appointment") }}
            </button>
          </div>
        </template>

        <div
          class="p-5"
          :class="deleteBackground ? '' : 'bg-white w-full shadow rounded-cu'"
        >
          <sign-in v-if="page === 1" />
          <sign-up v-if="page === 2" />
          <cin-verify :token="tokenActivation" v-if="page === 3" />
        </div>
      </template>

      <template v-if="isDone">
        <div class="flex flex-col gap-y-5 justify-center items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="112"
            height="112"
            fill="none"
            viewBox="0 0 112 112"
          >
            <path
              stroke="#30971F"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="12"
              d="M102.667 51.707V56c-.005 10.063-3.264 19.855-9.29 27.915-6.025 8.06-14.494 13.957-24.145 16.81-9.65 2.853-19.964 2.511-29.404-.977-9.44-3.487-17.499-9.933-22.976-18.375s-8.079-18.429-7.417-28.47c.662-10.042 4.553-19.6 11.091-27.25 6.538-7.65 15.374-12.981 25.19-15.199s20.086-1.203 29.278 2.893"
            />
            <path
              stroke="#30971F"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="12"
              d="M102.667 18.667L56 65.38l-14-14"
            />
          </svg>

          <div class="flex flex-col gap-y-1 items-center justify-center">
            <h1 class="font-EffraM text-2xl">
              {{ $t("patient.take_appointment_success") }}
            </h1>
            <span
              class="font-EffraR text-lg text-center"
              style="color: #616161"
            >
              {{ $t("patient.patient_recever_sms_appointment") }}
            </span>
          </div>

          <div
            class="bg-white w-full shadow rounded-cu p-5 flex flex-col gap-y-5"
          >
            <div class="grid gap-x-3 gap-y-5 grid-cols-2">
              <div
                class="flex flex-row gap-x-1 items-center font-EffraR"
                style="color: #6d6d6d"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="none"
                  viewBox="0 0 16 16"
                >
                  <path
                    stroke="#767676"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M2.666 10s.667-.667 2.667-.667c2 0 3.333 1.334 5.333 1.334S13.333 10 13.333 10V2s-.667.667-2.667.667c-2 0-3.333-1.334-5.333-1.334S2.666 2 2.666 2v8zM2.666 14.667V10"
                  />
                </svg>

                {{ $t("patient.status") }}
              </div>

              <span
                class="font-EffraR text-lg flex items-center gap-x-1"
                style="color: #ae851a"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="none"
                  viewBox="0 0 18 18"
                  class="animate-spin"
                >
                  <path
                    stroke="#AE851A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 1.5v3M9 13.5v3M3.697 3.698L5.82 5.82M12.18 12.18l2.122 2.122M1.5 9h3M13.5 9h3M3.697 14.302L5.82 12.18M12.18 5.82l2.122-2.122"
                  />
                </svg>
                {{ $t("patient.pending_confirmation") }}
              </span>
            </div>
            <appointment-info />
            <div class="grid gap-x-3 gap-y-5 grid-cols-2">
              <!-- Patient name -->
              <div class="flex flex-col">
                <div
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  style="color: #6d6d6d"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 16 16"
                  >
                    <path
                      stroke="#767676"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M13.333 14v-1.333c0-.708-.28-1.386-.78-1.886S11.373 10 10.666 10H5.333c-.707 0-1.385.281-1.885.781s-.781 1.178-.781 1.886V14M8 7.333c1.473 0 2.667-1.194 2.667-2.666C10.667 3.194 9.473 2 8 2 6.527 2 5.333 3.194 5.333 4.667c0 1.472 1.194 2.666 2.667 2.666z"
                    />
                  </svg>
                  {{ $t("patient.patient") }}
                </div>

                <span class="font-EffraR text-lg text-black">
                  {{
                    `${$auth.getUserInfo().firstName} ${
                      $auth.getUserInfo().lastName
                    }`
                  }}
                </span>
              </div>
              <!-- Motif -->
              <div class="flex flex-col">
                <div
                  class="flex flex-row gap-x-1 items-center font-EffraR"
                  style="color: #6d6d6d"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 16 16"
                  >
                    <g clip-path="url(#clip0)">
                      <path
                        stroke="#767676"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M4.667 4.667h.007m9.053 4.273l-4.78 4.78c-.124.124-.27.222-.432.29-.162.067-.336.101-.511.101s-.349-.034-.51-.102c-.162-.067-.31-.165-.433-.289L1.334 8V1.333h6.667l5.726 5.727c.249.25.388.588.388.94s-.14.69-.388.94z"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0">
                        <path fill="#fff" d="M0 0H16V16H0z" />
                      </clipPath>
                    </defs>
                  </svg>

                  {{ $t("patient.motif") }}
                </div>

                <span class="font-EffraR text-lg text-black">
                  {{ doneData.motifName || "--" }}
                </span>
              </div>
            </div>
          </div>

          <div class="flex justify-center items-center">
            <router-link
              to="/"
              class="
                bg-rosDok-ultra
                h-12
                px-10
                text-white
                border-0
                font-EffraM
                uppercase
                rounded-cu
                cursor-pointer
                flex
                justify-center
                items-center
              "
            >
              {{ $t("patient.back_home") }}
            </router-link>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus";
const cinVerify = () => import("@/views/global-components/cinVerify.vue");
const appointmentInfo = () => import("./components/appointmentInformation.vue");
const signIn = () => import("@/views/global-components/pa-signIn.vue");
const signUp = () => import("@/views/global-components/pa-signUp.vue");
import { get, kebabCase } from "lodash";
export default {
  components: { cinVerify, appointmentInfo, signIn, signUp },
  data() {
    return {
      localStorageParse: localStorage.getItem("booking_appointment"),
      page: 1,
      tokenActivation: null,
      deleteBackground: false,
      isDone: false,
      doneData: {},
    };
  },
  async created() {
    if (!this.localStorageParse) {
      return this.$router.push({ name: "doctorsList" });
    }

    if (!this.$auth.isAuthenticated()) {
      this.page = 1;
      return;
    }

    if (this.$auth.isAuthenticated()) {
      if (!this.$auth.getUserInfo().isMobileVerified) {
        // First Check Mobile Verification

        this.page = 2;
        return EventBus.$emit("Mobile_Verify_Patient_Not_Veriferd");
      }

      if (!this.$auth.getUserInfo().identityVerified) {
        // Second Check Indentity Verification
        this.page = 3;
        return;
      }

      // Take Appointment
      const { data, ok } = await this.$TAKE_APPOINTMENT();
      if (ok) {
        this.doneData.motifName = data.category.title;
        this.isDone = true;

        this.$nextTick(() => {
          localStorage.removeItem("booking_appointment");
        });
      }
    }
  },
  mounted() {
    EventBus.$on("Mobile_Verify_Patient_Not_Veriferd", (token) => {
      this.page = 2;
      this.$nextTick(() => {
        this.$vs.loading();
        setTimeout(() => {
          EventBus.$emit("SingUp_To_Activation_Mobile", token);
          this.$vs.loading.close();
        }, 500);
      });
    });

    EventBus.$on("TO-SIGNIN-PAGE", () => {
      this.page = 1;
    });

    EventBus.$on("TO-SIGNUP-PAGE", () => {
      this.page = 2;
    });

    EventBus.$on("Delete_bg_from_parent", () => {
      this.deleteBackground = true;
    });

    EventBus.$on("BOOKING-ACCOUNT-NOT-VERIFY", (token) => {
      this.tokenActivation = token;
      this.page = 3;
    });

    EventBus.$on("IS_DONE_BOOKING", (motif) => {
      this.doneData.motifName = motif;
      this.isDone = true;
      this.$nextTick(() => {
        localStorage.removeItem("booking_appointment");
      });
    });
  },
  methods: {
    goToDoctorProfile(doctor) {
      const speciality = kebabCase(
        get(doctor, "specialities[0].name", "medecin-generaliste")
      ).toLowerCase();
      const city = kebabCase(get(doctor, "cityName", "maroc")).toLowerCase();
      this.$router.push({
        name: "profileDoctor",
        params: { slug: doctor.slug, speciality, city },
      });
    },
  },
};
</script>

<style></style>
